import { FC, ReactElement } from 'react';
import { Field, useForm } from 'react-final-form';
import { RegistrationType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import Switch from 'rc-switch';
import {
  FIELDS_DISPLAY_FIELD_NAME,
  LEVELS_FIELD_NAME,
} from '../../../../constants/model.constants';
import { States } from '../../../../models/entities';
import { generateDefaultLevel } from '../../../../stores/utils';
import { Input } from '../../../components/input';
import { IRadioOption, RadioBlock } from '../../../components/radio-block';
import { ISelectOption, Select } from '../../../components/select';
import { ModelFormComponentProps } from '../interfaces';
import { useFormCommonStyles } from '../use-styles';
import {
  checkMinMaxValue,
  ignoreFloatingPointNumber,
  parseToNumber,
  requiredCheck,
} from '../utils';
import { useFieldsStyles } from './use-styles';

const COMMON_PARAMS = 'common_params';
const REGISTRATION_TYPE_FIELD_NAME = `${COMMON_PARAMS}.registration_type`;
const SHOW_QUESTIONS_OPTIONS = [
  {
    label: 'Отображать',
    value: true,
  },
  {
    label: 'Скрыть',
    value: false,
  },
];

export const CommonParams: FC<ModelFormComponentProps> = ({ testOptions }) => {
  const commonClasses = useFormCommonStyles();
  const fieldStyles = useFieldsStyles();
  const form = useForm();

  const { testLevels, levels, registrationTypes } = testOptions;

  const handleLevelChange = (option: ISelectOption): void => {
    const foundIndex = testLevels.findIndex((level) => level.value === option);
    const modelLevels = testLevels
      .slice(0, foundIndex + 1)
      .map(generateDefaultLevel);

    modelLevels[foundIndex].to = 100;

    form.change(`${COMMON_PARAMS}.level`, option);
    form.change(LEVELS_FIELD_NAME, modelLevels);
  };

  const handleRegistrationTypeChange = (option: IRadioOption): void => {
    if (option.value === RegistrationType.list) {
      form.change(`${FIELDS_DISPLAY_FIELD_NAME}.show_docs`, true);
    }

    form.change(REGISTRATION_TYPE_FIELD_NAME, option.value);
  };

  const handleShowQuestionsChange = (option: IRadioOption): void => {
    form.change(`${FIELDS_DISPLAY_FIELD_NAME}.show_questions`, option.value);
  };

  return (
    <Paper className={commonClasses.paper}>
      <h3 className={commonClasses.blockTitle}>Общие параметры</h3>
      <div className={commonClasses.fields}>
        <Field
          name={`${COMMON_PARAMS}.name`}
          validate={requiredCheck}
          render={({ input, meta: { error, touched } }): ReactElement => (
            <Input
              {...input}
              id={`${COMMON_PARAMS}.name`}
              label="Название модели"
              labelClassName={commonClasses.field}
              hintText={touched && error}
              state={touched && error ? States.Error : States.Default}
            />
          )}
        />
        <Field
          name={`${COMMON_PARAMS}.level`}
          render={({ input: { value } }): ReactElement => (
            <Select
              label="Уровень, до которого проводится оценка"
              options={levels}
              value={testLevels.find((testLevel) => testLevel.value === value)}
              className={commonClasses.field}
              onChange={handleLevelChange}
            />
          )}
        />
        <Field
          name={`${COMMON_PARAMS}.count_of_attempts`}
          validate={checkMinMaxValue({ min: 1, max: 5 })}
          render={({ input, meta: { touched, error } }): ReactElement => (
            <Input
              id={`${COMMON_PARAMS}.count_of_attempts`}
              type="number"
              value={input.value}
              onChange={(event): void => input.onChange(parseToNumber(event.target.value))
              }
              onKeyDown={ignoreFloatingPointNumber}
              label="Кол-во попыток на этап"
              hintText={touched && error}
              state={touched && error ? States.Error : States.Default}
            />
          )}
        />
        <Field
          name={`${COMMON_PARAMS}.max_count_of_testers`}
          validate={checkMinMaxValue({ min: 0 })}
          render={({ input, meta: { touched, error } }): ReactElement => (
            <Input
              id={`${COMMON_PARAMS}.max_count_of_testers`}
              type="number"
              value={input.value}
              onChange={(event): void => input.onChange(parseToNumber(event.target.value))
              }
              label="Макс. кол-во тестируемых"
              hintText={touched && error}
              state={touched && error ? States.Error : States.Default}
            />
          )}
        />
        <div className={cn(commonClasses.field, fieldStyles.flex)}>
          <Field
            name={REGISTRATION_TYPE_FIELD_NAME}
            render={({ input: { value } }): ReactElement => (
              <RadioBlock
                title="Тип регистрации"
                value={value}
                options={registrationTypes}
                onChange={handleRegistrationTypeChange}
              />
            )}
          />
          <Field
            name={`${COMMON_PARAMS}.certificates`}
            render={({ input: { value, onChange } }): ReactElement => (
              <label className={fieldStyles.certificate}>
                <Switch
                  onChange={onChange}
                  checked={value}
                  className={commonClasses.switcher}
                />
                <div>Сертификат по завершению</div>
              </label>
            )}
          />
        </div>
        <Field
          name={`${FIELDS_DISPLAY_FIELD_NAME}.show_questions`}
          render={({ input: { value } }): ReactElement => (
            <RadioBlock
              title="Отображение вопросов после завершения оценки"
              value={value}
              options={SHOW_QUESTIONS_OPTIONS}
              onChange={handleShowQuestionsChange}
              className={cn(commonClasses.field)}
            />
          )}
        />
        <div className={commonClasses.fieldWrapper}>
          <p className={commonClasses.title}>
            Данные, не являющиеся обязательными для прохождения оценки
          </p>
          <div className={cn(commonClasses.field, commonClasses.optionFieldsWrapper)}>
            <Field
              name={`${COMMON_PARAMS}.not_mandatory_email`}
              render={({ input: { value, onChange } }): ReactElement => (
                <label className={fieldStyles.optionField}>
                  <Switch
                    onChange={onChange}
                    checked={value}
                    className={commonClasses.switcher}
                  />
                  <div>Необязательный Email</div>
                </label>
              )}
            />
            <Field
              name={`${COMMON_PARAMS}.not_mandatory_snils`}
              render={({ input: { value, onChange } }): ReactElement => (
                <label className={fieldStyles.optionField}>
                  <Switch
                    onChange={onChange}
                    checked={value}
                    className={commonClasses.switcher}
                  />
                  <div>Необязательный СНИЛС</div>
                </label>
              )}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
};
