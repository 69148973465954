import {
  ModelStage,
  sdkGetModel,
  sdkRemoveModel,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { capitalize } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { FIELDS_DISPLAY_LABELS } from '../constants/model.constants';
import {
  IModelDetailStore,
  ModelDetail,
} from '../models/stores/model-detail.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class ModelDetailStore implements IModelDetailStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = false;
  public removeLoading = false;
  public model?: ModelDetail;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public reset = (): void => {
    this.loading = false;
    this.model = undefined;
  };

  public getModel = async(id: string): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true;
        this.model = undefined;
      });

      const response = await sdkGetModel({
        baseURL: this.rootStore.config.modelAPI,
        token: this.rootStore.user.token,
        data: { id },
      });

      const {
        levelLabels,
        data: testOptions,
        testTypeLabels,
        questionOutputLabels,
        questionTypeLabels,
        testTagsLabels,
        displayResultLabels,
      } = this.rootStore.dictionary;

      const { registrationTypes } = testOptions ?? {};

      const {
        common_params,
        fields_display,
        stages,
        test_options,
        stage,
        updated_at,
        levels,
        display_results,
      } = response;

      const {
        level,
        certificates,
        registration_type,
        count_of_attempts,
        max_count_of_testers,
        name,
        not_mandatory_email,
        not_mandatory_snils,
      } = common_params;

      const { test_type, output, weight_questions, weight_levels }
        = test_options;

      const foundRegistrationType = registrationTypes?.find(
        ({ value }) => value === registration_type,
      );

      const hideFields = Object.entries(fields_display).reduce(
        (acc, current) => {
          const [key, value] = current;
          const hideField = FIELDS_DISPLAY_LABELS[key];

          if (value || !hideField) {
            return acc;
          }

          return !acc ? hideField : `${acc}, ${hideField}`;
        },
        '',
      );

      const getNotMandatoryFields = (): string => {
        const result = [
          not_mandatory_email && 'Email',
          not_mandatory_snils && 'СНИЛС',
        ].filter(Boolean);

        if (result.length === 0) {
          return '-';
        }

        if (result.length < 2) {
          return result.toString();
        }

        return result.join(', ');
      };

      const weightQuestions = weight_questions.map((question) => ({
        key: question.type,
        type: capitalize(questionTypeLabels[question.type]),
        tag: capitalize(testTagsLabels[question.tag]),
        count: question.count,
        time: question.time_for_question,
        weight: question.weight,
      }));

      const result = {
        commonParams: {
          name,
          attemptsCount: count_of_attempts,
          testersCount: max_count_of_testers,
          level: capitalize(levelLabels[level]),
          certificates: certificates ? 'Да' : 'Нет',
          registrationType: capitalize(foundRegistrationType?.label),
          not_mandatory_fields: getNotMandatoryFields(),
        },
        stages,
        hideFields,
        fieldsDisplay: fields_display,
        testOptions: {
          testType: capitalize(testTypeLabels[test_type]),
          output: capitalize(questionOutputLabels[output]),
        },
        weightQuestions,
        weightLevels: weight_levels,
        displayResult: capitalize(displayResultLabels[display_results]),
        stage,
        levels,
        _id: id,
        updatedAt: updated_at,
      };

      runInAction(() => {
        this.model = result;
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
        toast({ type: 'error', text: getErrorMessage(error) });
      });
    }
  };

  public removeModel = async(): Promise<void> => {
    if (!this.model) {
      return;
    }
    const id = this.model._id;
    const stage = this.model.stage;
    try {
      runInAction(() => {
        this.loading = true;
      });

      await sdkRemoveModel({
        baseURL: this.rootStore.config.modelAPI,
        token: this.rootStore.user.token,
        data: { id },
      });

      if (stage === ModelStage.draft) {
        this.rootStore.modelSidebar.resetDraftAndRequest();
      }
      if (stage === ModelStage.published) {
        this.rootStore.modelSidebar.resetPublishedAndRequest();
      }

      this.loading = false;
    }
    catch (error) {
      this.loading = false;
      throw new Error(getErrorMessage(error));
    }
  };

}
